.grey-block {
    &:before {
        position: absolute;
        content: '';
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white-four;
        z-index: -1;
    }
}
