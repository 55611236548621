@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    $this: &;

    display: none;
    position: absolute;
    z-index: $layer-element-spinner;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.is-visible {
        display: block;

        &::after {
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            box-sizing: border-box;
            border-radius: 50%;
            border: 3px solid rgba($color-medium-green, 0.4);
            border-top-color: $color-dark-green !important;
            animation: spinner 0.6s linear infinite;
            @include tablet {
                width: 50px;
                height: 50px;
            }
        }
    }

    &.is-visible#{$this}--mobile-small {
        &::after {
            @include mobile-only {
                width: 20px;
                height: 20px;
                margin-top: -10px;
                margin-left: -10px;
                border-radius: 50%;
            }
        }
    }

    &.is-visible.is-faded {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.6);
            transition: opacity 0.2s linear, visibility 0.2s linear;
        }
    }
}

.spinner-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
