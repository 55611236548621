.pod {
    $this: &;

    padding: 20px;
    border-radius: 16px;
    background-color: $color-white-four;

    @include tablet {
        padding: 40px;
    }

    &__button {
        @include desktop {
            font-size: 16px;
        }
    }
}
