$color-white: #FFFFFF;
$color-white-two: #DCDCDC;
$color-white-three: #EBEBEB;
$color-white-four: #FAFAFA;
$color-white-five: #D8D8D8;
$color-light-grey: #ECECEC;
$color-lime-green: #C1D100;
$color-dark-green: #016C42;
$color-black: #222222;
$color-dark-grey: #50504E;
$color-medium-green: #3BAA35;
$color-greyish: #A8A8A7;
$color-teal: #63BAA9;
$color-dark-blue: #0D3F61;
$color-dark-blue-green: #00452A;
$color-gainsboro: #DDDDDD;
$color-vermillion: #E60E0E;
$color-burnt-red: #A10707;
