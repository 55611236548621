@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.wizard-main {
  display: flex;
  height: calc(100vh - 70px);
  position: relative;
  padding: 65px 20px 75px;
  flex-direction: column;
  overflow-y: clip; }
  @media (--only-mobile) {
    .wizard-main {
      margin-right: -20px;
      margin-left: -20px; } }
  @media (--tablet) {
    .wizard-main {
      height: calc(100vh - 150px);
      padding: 40px 30px 0 0; } }
  .wizard-main::before, .wizard-main::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FAFAFA; }
    @media (--tablet) {
      .wizard-main::before, .wizard-main::after {
        left: calc(-50vw + 50%);
        border-right: 2px solid #ECECEC; } }
  .wizard-main::after {
    z-index: 0;
    top: auto;
    border-bottom: 2px solid #ECECEC; }
  .wizard-main__suptitle {
    position: relative;
    z-index: 3;
    background-color: #FAFAFA;
    font-size: 12px;
    font-weight: 700;
    color: #A8A8A7;
    text-transform: uppercase;
    padding-top: 65px; }
    @media (--tablet) {
      .wizard-main__suptitle {
        padding-top: 40px; } }
  .wizard-main__container-artwork {
    margin: 0 -15px;
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: auto; }
  .wizard-main.show {
    animation: wizard-fade-in 0.2s linear normal both; }
  .wizard-main.hide {
    animation: wizard-fade-in 0.2s linear reverse both; }

@keyframes wizard-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.store-selector .replicator__container:first-of-type .replicator__remove-button {
  display: none; }

.customize-campaign .m-40, .customize-campaign .form__row {
  margin: 0; }

.wizard-summary {
  padding: 20px; }
  @media (--tablet) {
    .wizard-summary {
      height: calc(100vh - 75px);
      padding: 30px;
      overflow: auto; } }

.wizard-buttons {
  display: flex;
  height: 75px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF; }
  @media (--only-mobile) {
    .wizard-buttons {
      margin-right: -20px;
      margin-left: -20px; } }
  @media (--only-mobile) {
    .wizard-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw; } }
  @media (--tablet) {
    .wizard-buttons {
      padding: 0 30px 0 0;
      border-right: 2px solid #ECECEC; } }
  .wizard-buttons .btn-secondary:only-child {
    margin-left: auto; }

.wizard-disclaimer {
  font-size: 14px;
  align-self: end;
  max-width: 420px;
  margin-top: auto;
  padding-bottom: 20px; }
  .wizard-disclaimer__checkbox {
    padding-top: 10px;
    display: block; }
  .wizard-disclaimer .form-control__errors {
    margin-left: 0;
    margin-top: 10px; }
