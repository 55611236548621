@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.summary-list {
  margin: 0; }
  .summary-list + .summary-list::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    margin: 16px 0;
    background-color: #ECECEC; }
  .summary-list__title {
    font-size: 12px;
    font-weight: 700;
    color: #016C42;
    line-height: 2.83333; }
  .summary-list__content {
    margin: 0;
    font-size: 14px;
    line-height: 1.71429;
    letter-spacing: -0.29px;
    color: #222222; }
