.autocomplete {
    $this: &;

    display: inline-block;
    width: 100%;

    &[hidden] {
        display: none;
    }

    &__list-wrap {
        min-width: calc(100% - 60px);
        position: absolute;
        z-index: 40;
        left: 0;
        margin: 20px 30px 0;
        padding: 20px 0;
        border-radius: 9px;
        border: 1px solid $color-white-two;
        background: $color-white;

        &::after,
        &::before {
            content: ' ';
            width: 0;
            height: 0;
            position: absolute;
            bottom: 100%;
            left: 50px;
            border: solid transparent;
            pointer-events: none;
        }

        &::after {
            margin-left: -10px;
            border-color: transparent;
            border-bottom-color: $color-white;
            border-width: 10px;
        }

        &::before {
            margin-left: -11px;
            border-color: transparent;
            border-bottom-color: $color-white-two;
            border-width: 11px;
        }
    }

    &__list {
        margin: 0 5px 0 10px;
        padding: 0;
        max-height: 300px;
        overflow: auto;
        list-style: none;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;

        > li {
            position: relative;
            padding: 10px 15px;
            font-size: 18px;
            color: $color-black;
            cursor: pointer;

            &:hover,
            &[aria-selected='true'] {
                background-color: rgba($color-medium-green, 0.1);
            }

            mark {
                font-weight: 500;
                color: inherit;
                background-color: transparent;
            }
        }
    }

    .spinner {
        &::after {
            right: 5px;
            left: auto;
            @include tablet {
                right: 10px;
            }
        }
    }
}
