.card {
    $this: &;

    $triangle-size: 58px;

    display: flex;
    width: 100%;
    height: 100%;
    color: $color-greyish;
    flex-direction: column;

    &__control {
        @include visually-hidden();

        &:checked {
            & ~ #{$this}__label {
                border-color: $color-white-two;
                box-shadow: 0 2px 10px 0 rgba($color-black, 0.15);

                #{$this}__switch {
                    .icon {
                        opacity: 1;
                    }
                }

                #{$this}__media,
                #{$this}__title,
                #{$this}__grey {
                    color: $color-medium-green;
                }
            }
        }
    }

    &__label {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        border: 2px solid $color-light-grey;
        border-radius: 16px;
        background: $color-white;
        flex-direction: column;
        transition: $transition-default;

        &[for] {
            cursor: pointer;
        }
    }

    &__switch {
        display: flex;
        width: 27px;
        height: 27px;
        position: absolute;
        top: 13px;
        right: 13px;
        border-radius: 50%;
        background-color: $color-light-grey;
        color: $color-medium-green;
        justify-content: center;
        align-items: center;

        > .icon {
            opacity: 0;
            transition: opacity $transition-default;
        }
    }

    &__media {
        display: flex;
        position: relative;
        color: $color-greyish;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 17px 17px 0 0;

        @include mobile-only {
            max-height: 150px;
        }

        &::before {
            content: '';
            display: block;
            padding-bottom: 58%;
        }

        .icon {
            transition: $transition-default;
        }
    }

    &__media-customizable {
        display: none;
    }

    &__media-customizable-background,
    &__media-customizable-button {
        position: absolute;
        left: -30px;
        top: -30px;
        width: 58px;
        height: 58px;
        transform: skew(-45deg, 45deg);
    }

    &__media-customizable-background {
        background-color: $color-white;
        z-index: 1;
    }

    &__media-customizable-button {
        border: none;
        padding: 0;
        background-color: transparent;
        z-index: 2;
    }

    &__media-customizable-icon {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 1;
    }

    &__media-customizable-tooltip-content {
        color: $color-white;
        font-size: 12px;
        letter-spacing: 0;
    }

    &__media-customizable-tooltip-title {
        font-weight: 400;
    }

    &__title {
        font-size: 18px;
        font-weight: $ff-weight-medium;
        transition: color $transition-default;
    }

    &__grey {
        color: $color-greyish;
    }

    &__description {
        font-size: 14px;
        line-height: (20/14);
    }

    &__content {
        padding: 0 15px 15px;
        user-select: none;
        text-align: center;
    }

    &--customizable {
        #{$this}__image {
            border-radius: 20px 0 0 0;
        }

        #{$this}__media-customizable {
            display: flex;
        }
    }

    &--type-image {
        #{$this}__media {
            margin: -2px -2px 0;
            color: $color-greyish;
            background-color: $color-white-five;

            &::before {
                padding-bottom: 98%;
            }
        }

        #{$this}__image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        #{$this}__content {
            padding: 15px 15px 20px;
        }

        #{$this}__title {
            font-size: 14px;
            line-height: (20/14);
        }
    }

    &--type-logos {
        #{$this}__content {
            @include tablet {
                display: flex;
                flex-direction: column;
                flex-grow: 100;
            }
        }

        #{$this}__description {
            @include tablet {
                flex-grow: 100;
            }
        }

        #{$this}__btn {
            @include tablet {
                align-self: center;
            }
        }
    }

    #{$this}__tags {
        display: inline-flex;
        position: absolute;

        &--bottom-left {
            bottom: 10px;
            left: 10px;
        }

        &--bottom-right {
            bottom: 10px;
            right: 10px;
        }

        &--top-right {
            top: 10px;
            right: 10px;
        }
    }
}
