.ripple {
    $this: &;

    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    background-color: yellow;
    border-radius: 50%;
    opacity: 0.5;
    will-change: opacity, width, height;
}
