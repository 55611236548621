.#{$noUi-css-prefix}-tooltip {
    display: block;
    position: absolute;
    padding: 5px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFFFFF;
    color: #000000;
    text-align: center;
    white-space: nowrap;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-tooltip {
    bottom: 120%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-tooltip {
    top: 50%;
    right: 120%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
