@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.header {
  display: flex;
  height: 70px;
  position: relative;
  z-index: 3;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEBEB; }
  @media (--tablet) {
    .header {
      height: 75px;
      padding: 0 30px; } }
  .header__logo {
    display: inline-flex;
    width: 95px;
    height: 37px;
    flex-shrink: 0;
    margin-left: auto; }
    @media (--desktop) {
      .header__logo {
        width: 125px;
        height: 60px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto; } }
    .header__logo img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .header__buttons {
    display: flex;
    margin-left: auto; }
    .header__buttons > * + * {
      margin-left: 10px; }
      @media (--tablet) {
        .header__buttons > * + * {
          margin-left: 20px; } }
