.header {
    display: flex;
    height: 70px;
    position: relative;
    z-index: 3;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-bottom: 1px solid $color-white-three;

    @include tablet {
        height: 75px;
        padding: 0 30px;
    }

    &__logo {
        display: inline-flex;
        width: 95px;
        height: 37px;
        flex-shrink: 0;
        margin-left: auto;

        @include desktop {
            width: 125px;
            height: 60px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__buttons {
        display: flex;
        margin-left: auto;

        > * + * {
            margin-left: 10px;

            @include tablet {
                margin-left: 20px;
            }
        }
    }
}
