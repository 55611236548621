.form-input {
    $this: &;

    display: block;
    position: relative;

    &--small {
        max-width: 121px;

        @include tablet {
            max-width: 165px;
        }
    }

    &__control {
        @include form-input;

        font-family: inherit;
        transition: border-color $transition-default;

        &:not(:-webkit-autofill) {
            animation-name: onAutoFillCancel;
        }

        &:disabled {
            color: $color-greyish;
            cursor: not-allowed;
        }

        &--with-label {
            padding-top: 18px;
            padding-bottom: 5px;

            @include tablet {
                padding-top: 35px;
                padding-bottom: 15px;
            }
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &--with-icon {
            padding-left: 45px;
            background-repeat: no-repeat;
            background-position: 15px 50%;

            @include tablet {
                padding-left: 70px;
                background-position: 30px 50%;
            }

            ~ #{$this}__label {
                left: 45px;

                @include tablet {
                    left: 70px;
                }
            }
        }

        &:-webkit-autofill {
            animation-name: onAutoFillStart;
            transition: background-color 50000s ease-in-out 0s, border-color $transition-default;
        }

        .is-invalid & {
            border-color: $color-vermillion;
        }
    }

    &__icon {
        display: flex;
        width: 16px;
        position: absolute;
        top: 13px;
        left: 20px;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        @include tablet {
            top: 29px;
            left: 30px;
        }

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        &--right {
            left: auto;
            right: 20px;

            @include tablet {
                left: auto;
                right: 30px;
            }
        }

        &--green {
            color: $color-dark-green;
        }

        .icon {
            position: absolute;
            margin: auto;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 20px;
        transition: transform 100ms ease;
        transform: translateY((11/16) + em);
        font-size: 16px;
        color: $color-black;
        pointer-events: none;

        @include tablet {
            left: 30px;
            font-size: 18px;
            transform: translateY((25/18) + em);
        }
    }

    &__control:focus ~,
    &.is-filled,
    &.is-focused {
        #{$this}__label {
            font-size: 10px;
            transform: translateY(7px);
            @include tablet {
                font-size: 12px;
                transform: translateY(14px);
            }
        }
    }

    &.is-focused {
        #{$this}__control {
            border-color: $color-medium-green;
        }
    }
}

@keyframes onAutoFillStart {
    from {
        /**/
    }

    to {
        /**/
    }
}

@keyframes onAutoFillCancel {
    from {
        /**/
    }

    to {
        /**/
    }
}
