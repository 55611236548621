/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/

@mixin specsaversweb($weight) {
    font-family: $ff-fallback;
    font-weight: $weight;

    .fonts-loaded & {
        font-family: $ff-specsaversweb;
    }
}

@mixin ff-specsaversweb-regular() {
    @include specsaversweb($ff-weight-normal);
}

@mixin ff-specsaversweb-medium() {
    @include specsaversweb($ff-weight-medium);
}

@mixin ff-specsaversweb-bold() {
    @include specsaversweb($ff-weight-bold);
}
