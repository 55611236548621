.tag {
    display: inline-flex;
    padding: (2/10) + em (5/10) + em;
    font-size: 10px;
    font-weight: $ff-weight-medium;
    text-align: center;
    color: $color-white;
    text-transform: uppercase;
    background-color: $color-dark-green;
    line-height: normal;

    & + & {
        margin-left: 5px;
    }
}
