.form-control {
    $this: &;

    &__header {
        margin-bottom: 15px;

        @include tablet {
            margin-bottom: 20px;
        }
    }

    &__label {
        display: block;
        font-weight: $ff-weight-medium;
        color: $color-greyish;

        @include tablet {
            font-size: 18px;
            min-width: 60px;
        }

        &--size-xs {
            font-size: 12px;
            text-transform: uppercase;
        }
    }

    &__description {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        line-height: (21/12);
    }

    &__errors {
        @include reset-list();

        margin-top: 20px;
        margin-left: 30px;

        &:not(.filled) {
            display: none;
        }

        .wizard-buttons & {
            margin: 0;
        }
    }

    &__error-item {
        color: $color-vermillion;
        font-size: 14px;

        & + & {
            margin-top: 3px;
        }
    }

    &--inline {
        @include desktop {
            display: flex;
            align-items: center;

            #{$this}__header {
                min-width: 20%;
                margin-bottom: 0;
            }

            #{$this}__label {
                margin-right: 25px;

                &--size-xs {
                    margin-right: 15px;
                }
            }

            #{$this}__content {
                flex-grow: 1;
            }
        }

        @include desktop-wide {
            #{$this}__header {
                min-width: 15%;
            }
        }
    }

    &--range {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include desktop {
            flex-wrap: nowrap;
        }

        #{$this}__label {
            margin-right: 25px;
            flex-shrink: 0;

            &--size-xs {
                margin-right: 15px;
            }
        }

        #{$this}__content {
            flex-grow: 1;
            flex-shrink: 0;
        }

        #{$this}__errors {
            width: 100%;

            @include desktop {
                margin-top: 0;
            }
        }
    }
}

#global_errors {
    margin: 0 20px 0 auto;
}

.validation-summary-errors {
    font-size: 14px;
    color: $color-vermillion;
}
