/* global styles for SVG */

.icon {
    fill: currentColor;
    align-self: center;

    path {
        fill: inherit;
    }
}
