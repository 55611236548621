.wizard-disclaimer {
    font-size: 14px;
    align-self: end;
    max-width: 420px;
    margin-top: auto;
    padding-bottom: 20px;

    &__checkbox {
        padding-top: 10px;
        display: block;
    }

    .form-control__errors {
        margin-left: 0;
        margin-top: 10px;
    }
}
