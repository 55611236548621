.richtext {
    p {
        @include text-body-copy();

        margin-bottom: 24px;

        + [class*='title'] {
            margin-top: 32px;
        }
    }

    ul {
        @include text-body-copy();

        margin-bottom: 40px;
        padding-left: 10px;
        list-style: none;

        li {
            position: relative;
            padding-left: 25px;

            @include tablet {
                padding-left: 28px;
            }

            &::before {
                content: '';
                width: 3px;
                height: 3px;
                position: absolute;
                top: 11px;
                left: 0;
                background-color: $color-dark-grey;
                border-radius: 50%;
            }
        }
    }
}
