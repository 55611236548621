@import '../_mixins.scss';
@import '../_vars.scss';

$transparentLight: rgba(0, 8, 16, 0.08);
$transparentDark: rgba(0, 8, 16, 0.15);
$transparentDarker: rgba(0, 8, 16, 0.2);

.tippy-tooltip.light-border-theme {
    background-color: white;
    background-clip: padding-box;
    border: 1px solid $transparentDark;
    color: #26323D;
    box-shadow: 0 3px 14px -0.5px $transparentLight;

    .tippy-backdrop {
        background-color: white;
    }

    .tippy-arrow,
    .tippy-roundarrow {
        &::after,
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
        }
    }

    .tippy-roundarrow {
        fill: white;

        &::after {
            width: 24px;
            height: 8px;
            top: 0;
            left: 0;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPjxwYXRoIGQ9Ik0zLDhjMCwwIDIuMDIxLC0wLjAxNSA1LjI1MywtNC4yMThjMS4zMzEsLTEuNzMxIDIuNTQ0LC0yLjc3NSAzLjc0NywtMi43ODJjMS4yMDMsLTAuMDA3IDIuNDE2LDEuMDM1IDMuNzYxLDIuNzgyYzMuMjUxLDQuMjIzIDUuMjM5LDQuMjE4IDUuMjM5LDQuMjE4bC0xOCwwWiIgc3R5bGU9ImZpbGw6IzAwMDgxNjtmaWxsLW9wYWNpdHk6MC4yMDM5MjI7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9zdmc+);
            background-size: 24px 8px;
            fill: $transparentDark;
        }
    }
}

.tippy-tooltip.light-green-theme {
    background-color: $color-medium-green;

    .tippy-arrow {
        border-right: 8px solid $color-medium-green;
    }

    &.is-position {
        top: -15px !important;
        left: -35px !important;
    }
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme {
    .tippy-roundarrow::after {
        top: 1px;
        transform: rotate(180deg);
    }

    .tippy-arrow {
        border-top-color: #FFFFFF;

        &::before {
            bottom: -1px;
            border-top: 7px solid $transparentDarker;
        }

        &::after {
            top: -7px;
            border-top: 7px solid white;
        }
    }
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme {
    .tippy-roundarrow::after {
        top: -1px;
    }

    .tippy-arrow {
        border-bottom-color: #FFFFFF;

        &::before {
            bottom: -6px;
            border-bottom: 7px solid $transparentDarker;
        }

        &::after {
            bottom: -7px;
            border-bottom: 7px solid white;
        }
    }
}

.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme {
    .tippy-roundarrow::after {
        top: 0;
        left: 1px;
        transform: rotate(90deg);
    }

    .tippy-arrow {
        border-left-color: #FFFFFF;

        &::before {
            left: -6px;
            border-left: 7px solid $transparentDarker;
        }

        &::after {
            left: -7px;
            border-left: 7px solid white;
        }
    }
}

.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme {
    .tippy-roundarrow::after {
        top: 0;
        left: -1px;
        transform: rotate(-90deg);
    }

    .tippy-arrow {
        border-right-color: #FFFFFF;

        &::before {
            right: -6px;
            border-right: 7px solid $transparentDarker;
        }

        &::after {
            right: -7px;
            border-right: 7px solid white;
        }
    }
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme,
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme {
    .tippy-arrow,
    .tippy-roundarrow {
        transform: translateX(-1px);
    }

    .tippy-arrow {
        &::after,
        &::before {
            left: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
        }
    }
}

.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme,
.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme {
    .tippy-arrow,
    .tippy-roundarrow {
        transform: translateY(-1px);
    }

    .tippy-arrow {
        &::after,
        &::before {
            top: -7px;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
        }
    }
}
