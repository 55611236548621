@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.form-input {
  display: block;
  position: relative; }
  .form-input--small {
    max-width: 121px; }
    @media (--tablet) {
      .form-input--small {
        max-width: 165px; } }
  .form-input__control {
    width: 100%;
    padding: 10px 20px;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 999px;
    font-size: 16px;
    color: #222222;
    font-family: inherit;
    transition: border-color 0.3s ease-out; }
    @media (--tablet) {
      .form-input__control {
        padding: 23.5px 30px;
        font-size: 18px; } }
    .is-invalid .form-input__control {
      border-color: #E60E0E; }
    .form-input__control:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel; }
    .form-input__control:disabled {
      color: #A8A8A7;
      cursor: not-allowed; }
    .form-input__control--with-label {
      padding-top: 18px;
      padding-bottom: 5px; }
      @media (--tablet) {
        .form-input__control--with-label {
          padding-top: 35px;
          padding-bottom: 15px; } }
    .form-input__control::-webkit-inner-spin-button, .form-input__control::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .form-input__control--with-icon {
      padding-left: 45px;
      background-repeat: no-repeat;
      background-position: 15px 50%; }
      @media (--tablet) {
        .form-input__control--with-icon {
          padding-left: 70px;
          background-position: 30px 50%; } }
      .form-input__control--with-icon ~ .form-input__label {
        left: 45px; }
        @media (--tablet) {
          .form-input__control--with-icon ~ .form-input__label {
            left: 70px; } }
    .form-input__control:-webkit-autofill {
      animation-name: onAutoFillStart;
      transition: background-color 50000s ease-in-out 0s, border-color 0.3s ease-out; }
    .is-invalid .form-input__control {
      border-color: #E60E0E; }
  .form-input__icon {
    display: flex;
    width: 16px;
    position: absolute;
    top: 13px;
    left: 20px;
    justify-content: center;
    align-items: center;
    pointer-events: none; }
    @media (--tablet) {
      .form-input__icon {
        top: 29px;
        left: 30px; } }
    .form-input__icon::before {
      content: '';
      display: block;
      padding-top: 100%; }
    .form-input__icon--right {
      left: auto;
      right: 20px; }
      @media (--tablet) {
        .form-input__icon--right {
          left: auto;
          right: 30px; } }
    .form-input__icon--green {
      color: #016C42; }
    .form-input__icon .icon {
      position: absolute;
      margin: auto; }
  .form-input__label {
    position: absolute;
    top: 0;
    left: 20px;
    transition: transform 100ms ease;
    transform: translateY(0.6875em);
    font-size: 16px;
    color: #222222;
    pointer-events: none; }
    @media (--tablet) {
      .form-input__label {
        left: 30px;
        font-size: 18px;
        transform: translateY(1.38889em); } }
  .form-input__control:focus ~ .form-input__label, .form-input.is-filled .form-input__label, .form-input.is-focused .form-input__label {
    font-size: 10px;
    transform: translateY(7px); }
    @media (--tablet) {
      .form-input__control:focus ~ .form-input__label, .form-input.is-filled .form-input__label, .form-input.is-focused .form-input__label {
        font-size: 12px;
        transform: translateY(14px); } }
  .form-input.is-focused .form-input__control {
    border-color: #3BAA35; }

@keyframes onAutoFillStart {
  from {
    /**/ }
  to {
    /**/ } }

@keyframes onAutoFillCancel {
  from {
    /**/ }
  to {
    /**/ } }
