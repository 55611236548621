@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.card {
  display: flex;
  width: 100%;
  height: 100%;
  color: #A8A8A7;
  flex-direction: column; }
  .card__control {
    width: 1px;
    height: 1px;
    position: absolute;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    white-space: nowrap;
    -webkit-clip-path: inset(100%); }
    .card__control:checked ~ .card__label {
      border-color: #DCDCDC;
      box-shadow: 0 2px 10px 0 rgba(34, 34, 34, 0.15); }
      .card__control:checked ~ .card__label .card__switch .icon {
        opacity: 1; }
      .card__control:checked ~ .card__label .card__media,
      .card__control:checked ~ .card__label .card__title,
      .card__control:checked ~ .card__label .card__grey {
        color: #3BAA35; }
  .card__label {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    border: 2px solid #ECECEC;
    border-radius: 16px;
    background: #FFFFFF;
    flex-direction: column;
    transition: 0.3s ease-out; }
    .card__label[for] {
      cursor: pointer; }
  .card__switch {
    display: flex;
    width: 27px;
    height: 27px;
    position: absolute;
    top: 13px;
    right: 13px;
    border-radius: 50%;
    background-color: #ECECEC;
    color: #3BAA35;
    justify-content: center;
    align-items: center; }
    .card__switch > .icon {
      opacity: 0;
      transition: opacity 0.3s ease-out; }
  .card__media {
    display: flex;
    position: relative;
    color: #A8A8A7;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 17px 17px 0 0; }
    @media (--only-mobile) {
      .card__media {
        max-height: 150px; } }
    .card__media::before {
      content: '';
      display: block;
      padding-bottom: 58%; }
    .card__media .icon {
      transition: 0.3s ease-out; }
  .card__media-customizable {
    display: none; }
  .card__media-customizable-background, .card__media-customizable-button {
    position: absolute;
    left: -30px;
    top: -30px;
    width: 58px;
    height: 58px;
    transform: skew(-45deg, 45deg); }
  .card__media-customizable-background {
    background-color: #FFFFFF;
    z-index: 1; }
  .card__media-customizable-button {
    border: none;
    padding: 0;
    background-color: transparent;
    z-index: 2; }
  .card__media-customizable-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1; }
  .card__media-customizable-tooltip-content {
    color: #FFFFFF;
    font-size: 12px;
    letter-spacing: 0; }
  .card__media-customizable-tooltip-title {
    font-weight: 400; }
  .card__title {
    font-size: 18px;
    font-weight: 500;
    transition: color 0.3s ease-out; }
  .card__grey {
    color: #A8A8A7; }
  .card__description {
    font-size: 14px;
    line-height: 1.42857; }
  .card__content {
    padding: 0 15px 15px;
    user-select: none;
    text-align: center; }
  .card--customizable .card__image {
    border-radius: 20px 0 0 0; }
  .card--customizable .card__media-customizable {
    display: flex; }
  .card--type-image .card__media {
    margin: -2px -2px 0;
    color: #A8A8A7;
    background-color: #D8D8D8; }
    .card--type-image .card__media::before {
      padding-bottom: 98%; }
  .card--type-image .card__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover; }
  .card--type-image .card__content {
    padding: 15px 15px 20px; }
  .card--type-image .card__title {
    font-size: 14px;
    line-height: 1.42857; }
  @media (--tablet) {
    .card--type-logos .card__content {
      display: flex;
      flex-direction: column;
      flex-grow: 100; } }
  @media (--tablet) {
    .card--type-logos .card__description {
      flex-grow: 100; } }
  @media (--tablet) {
    .card--type-logos .card__btn {
      align-self: center; } }
  .card .card__tags {
    display: inline-flex;
    position: absolute; }
    .card .card__tags--bottom-left {
      bottom: 10px;
      left: 10px; }
    .card .card__tags--bottom-right {
      bottom: 10px;
      right: 10px; }
    .card .card__tags--top-right {
      top: 10px;
      right: 10px; }
