.wizard-main {
    $this: &;
    @include mobile-expanded;

    display: flex;
    height: calc(100vh - 70px);
    position: relative;
    padding: 65px 20px $wizard-buttons-height;
    flex-direction: column;

    // Fix for #104228 - Screen scrolling & jumping bug
    overflow-y: clip;

    @include tablet {
        height: calc(100vh - 150px);
        padding: 40px 30px 0 0;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-white-four;

        @include tablet {
            left: calc(-50vw + 50%);
            border-right: 2px solid $color-light-grey;
        }
    }

    &::after {
        z-index: 0;
        top: auto;
        border-bottom: 2px solid $color-light-grey;
    }

    &__suptitle {
        position: relative;
        z-index: 3;
        background-color: $color-white-four;
        font-size: 12px;
        font-weight: $ff-weight-bold;
        color: $color-greyish;
        text-transform: uppercase;
        padding-top: 65px;

        @include tablet {
            padding-top: 40px;
        }
    }

    &__container-artwork {
        margin: 0 -15px;
        padding: 0 15px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &.show {
        animation: wizard-fade-in 0.2s linear normal both;
    }

    &.hide {
        animation: wizard-fade-in 0.2s linear reverse both;
    }

    @keyframes wizard-fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

.store-selector {
    .replicator__container:first-of-type {
        .replicator__remove-button {
            display: none;
        }
    }
}
