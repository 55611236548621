/* general grid styles */

$grid-columns: 12;
$grid-gutter: 30px;

$gutters: (
    'zero': 0,
    'xs': 10px,
    's': 20px,
    'm': 30px
);

@mixin col {
    width: 100%;
    flex-basis: 100%;
    box-sizing: border-box;
    min-height: 1px;

    &.no-gutter {
        padding: 0;
    }
}

.row {
    $this: &;

    display: flex;
    margin-right: -$grid-gutter / 2;
    margin-left: -$grid-gutter / 2;
    flex-wrap: wrap;
    flex-grow: 1;

    > [class*='col-'] {
        @include col();

        padding-right: $grid-gutter / 2;
        padding-left: $grid-gutter / 2;
    }

    &.no-gutter {
        margin-right: 0;
        margin-left: 0;

        > [class*='col-'] {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &--center {
        justify-content: center;
    }

    &--vertical-center {
        align-items: center;
    }

    &--tiles {
        margin-top: -$grid-gutter / 2;
        margin-bottom: -$grid-gutter / 2;

        > [class*='col-'] {
            margin-top: $grid-gutter / 2;
            margin-bottom: $grid-gutter / 2;
        }
    }
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: $size / $columns;

    margin-left: if($num == 0, 0, percentage($num));
}

@mixin grid($containerColumnsCount, $gutter, $breakpoints, $gutters, $columns: $grid-columns) {
    $maxColWidth: 100 / $containerColumnsCount;

    @each $gutterName, $gutterValue in $gutters {
        .row--initial-gutter-#{$gutterName} {
            margin-right: -$gutterValue / 2;
            margin-left: -$gutterValue / 2;

            > [class*='col-'] {
                padding-right: $gutterValue / 2;
                padding-left: $gutterValue / 2;
            }
        }

        .row--tiles.row--initial-gutter-#{$gutterName} {
            margin-top: -$gutterValue / 2;
            margin-bottom: -$gutterValue / 2;

            > [class*='col-'] {
                margin-top: $gutterValue / 2;
                margin-bottom: $gutterValue / 2;
            }
        }
    }

    @each $size, $value in $breakpoints {
        @media only screen and (min-width: $value) {
            @each $gutterName, $gutterValue in $gutters {
                .row--#{$size}-gutter-#{$gutterName} {
                    margin-right: -$gutterValue / 2;
                    margin-left: -$gutterValue / 2;

                    > [class*='col-'] {
                        padding-right: $gutterValue / 2;
                        padding-left: $gutterValue / 2;
                    }
                }
            }
        }
    }

    @each $size, $value in $breakpoints {
        @media only screen and (min-width: $value) {
            @each $gutterName, $gutterValue in $gutters {
                .row--tiles.row--#{$size}-gutter-#{$gutterName} {
                    margin-top: -$gutterValue / 2;
                    margin-bottom: -$gutterValue / 2;

                    > [class*='col-'] {
                        margin-top: $gutterValue / 2;
                        margin-bottom: $gutterValue / 2;
                    }
                }
            }
        }
    }

    @for $i from 1 through $containerColumnsCount {
        .row > .col-#{$i} {
            max-width: $maxColWidth * $i + 0%;
            flex-basis: $maxColWidth * $i + 0%;
        }
    }

    @each $size, $value in $breakpoints {
        @media only screen and (min-width: $value) {
            @for $column from 1 through $containerColumnsCount {
                .offset-#{$size}-#{$column} {
                    @include make-col-offset($column, $columns);
                }

                .row > .col-#{$size}-#{$column} {
                    max-width: $maxColWidth * $column + 0%;
                    flex-basis: $maxColWidth * $column + 0%;
                }

                .row > .col-#{$size}-auto {
                    width: auto;
                    max-width: none;
                    flex-basis: auto;
                }
            }
        }
    }
}

@include grid($grid-columns, $grid-gutter, $breakpoints, $gutters);
