.form-input-characters-manager {
    padding: 40px 0;
    border-bottom: 1px solid $color-light-grey;

    &__content-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__message {
        color: $color-medium-green;
        font-size: 14px;
        letter-spacing: 0;

        &.is-exceeded {
            color: $color-vermillion;
        }
    }
}
