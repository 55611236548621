.scrollable {
    $this: &;

    margin-right: -20px;
    padding: 0 20px 20px 0;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;

    @include tablet {
        margin-right: -30px;
        margin-left: -10px;
        padding: 0 30px 40px 10px;
    }

    &--type-1 {
        margin-left: -20px;
        padding-left: 20px;

        @include tablet {
            margin-left: -20px;
            padding-right: 20px;
        }
    }
}
