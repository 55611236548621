.section-header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: $color-white-four;
    padding: 0.5rem 0 2rem 0;

    &__title {
        display: inline-flex;
        color: $color-black;
        align-items: center;
    }

    &__btn {
        flex-shrink: 0;
    }

    .form-control {
        margin-left: 40px;
    }
}
