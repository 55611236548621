.summary-list {
    margin: 0;

    & + & {
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            margin: 16px 0;
            background-color: $color-light-grey;
        }
    }

    &__title {
        font-size: 12px;
        font-weight: $ff-weight-bold;
        color: $color-dark-green;
        line-height: (34/12);
    }

    &__content {
        margin: 0;
        font-size: 14px;
        line-height: (24/14);
        letter-spacing: -0.29px;
        color: $color-black;
    }
}
