@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.modal-button__content-wrapper {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-left: 0;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5%; }
  @media (--mobile-wide) {
    .modal-button__content-wrapper {
      padding: 4% 25%; } }

.modal-button__close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none; }
  .modal-button__close:hover {
    color: black; }

.modal-button__content {
  z-index: 9998;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 20px;
  padding: 2em;
  background: white; }
