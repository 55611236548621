/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.#{$noUi-css-prefix}-target,
.#{$noUi-css-prefix}-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.#{$noUi-css-prefix}-target {
    position: relative;
    direction: ltr;
}

.#{$noUi-css-prefix}-base,
.#{$noUi-css-prefix}-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
/* Wrapper for all connect elements.
 */
.#{$noUi-css-prefix}-connects {
    z-index: 0;
    overflow: hidden;
}

.#{$noUi-css-prefix}-connect,
.#{$noUi-css-prefix}-origin {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    will-change: transform;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir='rtl']) .#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-origin {
    right: 0;
    left: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-origin {
    width: 0;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-origin {
    height: 0;
}

.#{$noUi-css-prefix}-handle {
    position: absolute;
}

.#{$noUi-css-prefix}-touch-area {
    width: 100%;
    height: 100%;
}

.#{$noUi-css-prefix}-state-tap .#{$noUi-css-prefix}-connect,
.#{$noUi-css-prefix}-state-tap .#{$noUi-css-prefix}-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.#{$noUi-css-prefix}-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.#{$noUi-css-prefix}-horizontal {
    height: 18px;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle {
    width: 34px;
    height: 28px;
    top: -6px;
    left: -17px;
}

.#{$noUi-css-prefix}-vertical {
    width: 18px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle {
    width: 28px;
    height: 34px;
    top: -17px;
    left: -6px;
}

html:not([dir='rtl']) .#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle {
    right: -17px;
    left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.#{$noUi-css-prefix}-target {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBBBBB;
}

.#{$noUi-css-prefix}-connects {
    border-radius: 3px;
}

.#{$noUi-css-prefix}-connect {
    background: #3FB8AF;
}

/* Handles and cursors;
 */
.#{$noUi-css-prefix}-draggable {
    cursor: ew-resize;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-draggable {
    cursor: ns-resize;
}

.#{$noUi-css-prefix}-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFFFFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFFFFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBBBBB;
}

.#{$noUi-css-prefix}-active {
    box-shadow: inset 0 0 1px #FFFFFF, inset 0 1px 7px #DDDDDD, 0 3px 6px -3px #BBBBBB;
}

/* Handle stripes;
 */
.#{$noUi-css-prefix}-handle::before,
.#{$noUi-css-prefix}-handle::after {
    content: '';
    display: block;
    width: 1px;
    height: 14px;
    position: absolute;
    top: 6px;
    left: 14px;
    background: #E8E7E6;
}

.#{$noUi-css-prefix}-handle::after {
    left: 17px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle::before,
.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle::after {
    width: 14px;
    height: 1px;
    top: 14px;
    left: 6px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle::after {
    top: 17px;
}

/* Disabled state;
 */

[disabled] .#{$noUi-css-prefix}-connect {
    background: #B8B8B8;
}

[disabled].#{$noUi-css-prefix}-target,
[disabled].#{$noUi-css-prefix}-handle,
[disabled] .#{$noUi-css-prefix}-handle {
    cursor: not-allowed;
}
