.toggle-area {
    @include mobile-only {
        width: 100%;
        position: absolute;
        z-index: 1;
        top: 70px;
        left: 0;
        padding: 0;
    }

    &__button {
        @include reset-button;

        cursor: default;

        @include mobile-only {
            display: flex;
            width: 100%;
            height: 42px;
            padding: 0 20px;
            background-color: $color-dark-green;
            font-size: 16px;
            color: $color-white;
            justify-content: space-between;
        }

        &.is-open {
            .icon {
                transform: rotate(-180deg);
            }
        }

        .icon {
            transition: transform $transition-default;
            @include tablet {
                display: none;
            }
        }
    }

    &__area {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;

        @include mobile-only {
            height: calc(100vh - 190px);
            padding: 20px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            will-change: scroll-position;
            background-color: $color-white;
        }

        @include tablet {
            position: static;
        }

        &.disabled {
            display: none;

            @include tablet {
                display: block;
            }
        }

        &.go-show {
            animation: showArea 0.3s ease-out normal both;

            @include tablet {
                animation: none;
            }
        }

        &.go-hide {
            animation: showArea 0.3s ease-in reverse both;

            @include tablet {
                animation: none;
            }
        }
    }
}

@keyframes showArea {
    0% {
        z-index: -1;
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        z-index: -1;
        opacity: 1;
        transform: translateY(0);
    }
}
