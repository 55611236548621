@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  z-index: 0;
  overflow: hidden; }

.noUi-connect,
.noUi-origin {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  will-change: transform;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

/* Offset direction
 */
html:not([dir='rtl']) .noUi-horizontal .noUi-origin {
  right: 0;
  left: auto; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-touch-area {
  width: 100%;
  height: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  left: -17px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  top: -17px;
  left: -6px; }

html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBBBBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFFFFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFFFFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBBBBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFFFFF, inset 0 1px 7px #DDDDDD, 0 3px 6px -3px #BBBBBB; }

/* Handle stripes;
 */
.noUi-handle::before,
.noUi-handle::after {
  content: '';
  display: block;
  width: 1px;
  height: 14px;
  position: absolute;
  top: 6px;
  left: 14px;
  background: #E8E7E6; }

.noUi-handle::after {
  left: 17px; }

.noUi-vertical .noUi-handle::before,
.noUi-vertical .noUi-handle::after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px; }

.noUi-vertical .noUi-handle::after {
  top: 17px; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #CCCCCC;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCCCCC; }

.noUi-marker-sub {
  background: #AAAAAA; }

.noUi-marker-large {
  background: #AAAAAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  width: 100%;
  height: 80px;
  top: 100%;
  left: 0;
  padding: 10px 0; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }
  .noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  height: 100%;
  top: 0;
  left: 100%;
  padding: 0 10px; }

.noUi-value-vertical {
  padding-left: 25px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%); }
  .noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  padding: 5px;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFFFFF;
  color: #000000;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%); }

.form-range {
  display: flex;
  position: relative;
  flex-direction: column; }
  .form-range::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(250, 250, 250, 0.5);
    transition: opacity 0.3s ease-out; }
  .form-range__label {
    position: absolute;
    bottom: 100%;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: normal;
    color: #222222; }
    .form-range__label--min {
      left: 0; }
    .form-range__label--max {
      right: 0; }

.noUi-horizontal {
  height: 10px;
  padding: 0;
  border-radius: 999px;
  border: 0;
  background-color: #FFFFFF;
  box-shadow: none; }
  .noUi-horizontal .noUi-handle {
    width: 40px;
    height: 40px;
    top: -15px;
    border: 0;
    border-radius: 50%;
    background-color: #3BAA35;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13); }
    .noUi-horizontal .noUi-handle::before, .noUi-horizontal .noUi-handle::after {
      content: normal; }
  .noUi-horizontal .noUi-buffer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ECECEC; }
  html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
    right: -20px; }
