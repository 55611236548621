.store-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    @include mobile-wide {
        flex-direction: row;
    }

    &[hidden] {
        display: none;
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include mobile-wide {
            width: 33.3333333%;
        }
    }

    &__message {
        margin-top: 40px;
        color: $color-medium-green;
        text-align: center;
    }
}