@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.btn {
    @include default-cta;

    display: inline-flex;
    position: relative;
    z-index: 0;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border: 0;
    overflow: hidden;
    text-align: center;
    justify-content: center;
    transition: background-color $transition-default, color $transition-default,
        border-color $transition-default;

    &.is-loading {
        .icon--loader {
            transform-origin: 50% calc(50% + 1px);
            animation: spinner 0.6s linear infinite;
        }

        .icon--loader-disappearing {
            display: block;
        }
    }

    .icon--loader-disappearing {
        display: none;
    }

    span {
        position: relative;
        z-index: 1;
        pointer-events: none;

        + span {
            margin-left: 15px;
            font-weight: $ff-weight-normal;
        }
    }

    .icon {
        z-index: 1;
        flex-shrink: 0;
        pointer-events: none;
    }
}

.btn-primary {
    @extend .btn;

    @include button-medium;
    @include button-rounded;
    @include button-medium-green;
}

.btn-secondary {
    @extend .btn;

    @include button-small;
    @include button-rounded;
    @include button-with-grey-border;

    background-color: $color-white;

    &--with-green-border {
        @include button-with-green-color;
        @include button-with-green-border;
    }

    &--grey {
        @include button-grey;
    }

    &--medium-green {
        @include button-medium-green;
    }

    &--medium-white {
        display: inline-flex;
        min-width: 130px;
        padding: 20px;
        justify-content: flex-start;

        .icon--loader {
            margin-left: 20px;
        }
    }

    span + .icon {
        margin-left: 15px;
    }

    .icon + span {
        margin-left: 15px;
    }
}

.btn-frameless {
    @extend .btn;

    @include button-with-green-color;

    font-size: 18px;

    &--medium-green {
        @include button-with-medium-green-color;
    }

    &--red {
        @include button-with-red-color;
    }
}

.is-enabled {
    @include button-medium-green;
}

.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    display: inline-block;
    pointer-events: none;
}
