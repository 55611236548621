﻿.list-with-filter {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% - 135px);
}

[data-anime-item] {
    opacity: 0;
}
