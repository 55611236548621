@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.tippy-tooltip.light-border-theme {
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, 0.15);
  color: #26323D;
  box-shadow: 0 3px 14px -0.5px rgba(0, 8, 16, 0.08); }
  .tippy-tooltip.light-border-theme .tippy-backdrop {
    background-color: white; }
  .tippy-tooltip.light-border-theme .tippy-arrow::after, .tippy-tooltip.light-border-theme .tippy-arrow::before,
  .tippy-tooltip.light-border-theme .tippy-roundarrow::after,
  .tippy-tooltip.light-border-theme .tippy-roundarrow::before {
    content: '';
    position: absolute;
    z-index: -1; }
  .tippy-tooltip.light-border-theme .tippy-roundarrow {
    fill: white; }
    .tippy-tooltip.light-border-theme .tippy-roundarrow::after {
      width: 24px;
      height: 8px;
      top: 0;
      left: 0;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPjxwYXRoIGQ9Ik0zLDhjMCwwIDIuMDIxLC0wLjAxNSA1LjI1MywtNC4yMThjMS4zMzEsLTEuNzMxIDIuNTQ0LC0yLjc3NSAzLjc0NywtMi43ODJjMS4yMDMsLTAuMDA3IDIuNDE2LDEuMDM1IDMuNzYxLDIuNzgyYzMuMjUxLDQuMjIzIDUuMjM5LDQuMjE4IDUuMjM5LDQuMjE4bC0xOCwwWiIgc3R5bGU9ImZpbGw6IzAwMDgxNjtmaWxsLW9wYWNpdHk6MC4yMDM5MjI7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9zdmc+);
      background-size: 24px 8px;
      fill: rgba(0, 8, 16, 0.15); }

.tippy-tooltip.light-green-theme {
  background-color: #3BAA35; }
  .tippy-tooltip.light-green-theme .tippy-arrow {
    border-right: 8px solid #3BAA35; }
  .tippy-tooltip.light-green-theme.is-position {
    top: -15px !important;
    left: -35px !important; }

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-roundarrow::after {
  top: 1px;
  transform: rotate(180deg); }

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-arrow {
  border-top-color: #FFFFFF; }
  .tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-arrow::before {
    bottom: -1px;
    border-top: 7px solid rgba(0, 8, 16, 0.2); }
  .tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-arrow::after {
    top: -7px;
    border-top: 7px solid white; }

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-roundarrow::after {
  top: -1px; }

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-arrow {
  border-bottom-color: #FFFFFF; }
  .tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-arrow::before {
    bottom: -6px;
    border-bottom: 7px solid rgba(0, 8, 16, 0.2); }
  .tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-arrow::after {
    bottom: -7px;
    border-bottom: 7px solid white; }

.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-roundarrow::after {
  top: 0;
  left: 1px;
  transform: rotate(90deg); }

.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-arrow {
  border-left-color: #FFFFFF; }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-arrow::before {
    left: -6px;
    border-left: 7px solid rgba(0, 8, 16, 0.2); }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-arrow::after {
    left: -7px;
    border-left: 7px solid white; }

.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-roundarrow::after {
  top: 0;
  left: -1px;
  transform: rotate(-90deg); }

.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-arrow {
  border-right-color: #FFFFFF; }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-arrow::before {
    right: -6px;
    border-right: 7px solid rgba(0, 8, 16, 0.2); }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-arrow::after {
    right: -7px;
    border-right: 7px solid white; }

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-roundarrow,
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-roundarrow {
  transform: translateX(-1px); }

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-arrow::after, .tippy-popper[x-placement^='top'] .tippy-tooltip.light-border-theme .tippy-arrow::before,
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-arrow::after,
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-border-theme .tippy-arrow::before {
  left: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent; }

.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-roundarrow,
.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-roundarrow {
  transform: translateY(-1px); }

.tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-arrow::after, .tippy-popper[x-placement^='left'] .tippy-tooltip.light-border-theme .tippy-arrow::before,
.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-arrow::after,
.tippy-popper[x-placement^='right'] .tippy-tooltip.light-border-theme .tippy-arrow::before {
  top: -7px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; }

.dropdown {
  display: inline-flex; }
  .dropdown__content {
    max-height: 500px;
    overflow-y: auto; }
    @media (--only-mobile) {
      .dropdown__content {
        max-height: 80vh; } }
  .dropdown__button {
    padding: 10px 20px;
    font-size: 14px;
    color: #50504E;
    border: 1px solid #DCDCDC;
    border-radius: 20px;
    justify-content: space-between;
    transition: border-radius 0.1s linear; }
    .dropdown__button:disabled {
      color: #A8A8A7;
      cursor: not-allowed; }
      .can-hover .dropdown__button:disabled:hover {
        color: #A8A8A7; }
  .dropdown.is-open .dropdown__button {
    border-radius: 20px 20px 0 0;
    border-bottom-color: transparent; }
  .dropdown.is-open .icon--arrow {
    transform: rotate(-180deg); }
  @media (--only-mobile) {
    .dropdown--min-on-mobile .dropdown__button {
      width: 40px;
      height: 40px;
      padding: 0;
      justify-content: center;
      align-items: center; }
    .dropdown--min-on-mobile .dropdown__current-selection {
      display: none; }
      .dropdown--min-on-mobile .dropdown__current-selection + .icon {
        margin: 0; } }
  .dropdown .icon {
    transition: 0.3s ease-out; }
