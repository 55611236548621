@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.spinner {
  display: none;
  position: absolute;
  z-index: 300;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .spinner.is-visible {
    display: block; }
    .spinner.is-visible::after {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      box-sizing: border-box;
      border-radius: 50%;
      border: 3px solid rgba(59, 170, 53, 0.4);
      border-top-color: #016C42 !important;
      animation: spinner 0.6s linear infinite; }
      @media (--tablet) {
        .spinner.is-visible::after {
          width: 50px;
          height: 50px; } }
  @media (--only-mobile) {
    .spinner.is-visible.spinner--mobile-small::after {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%; } }
  .spinner.is-visible.is-faded::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    transition: opacity 0.2s linear, visibility 0.2s linear; }

.spinner-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
