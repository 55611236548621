.replicator {

    &__container {
        position: relative;

        &.show {
            animation: fade-container 0.3s ease-out normal both;
        }

        &.hide {
            animation: fade-container 0.3s ease-in reverse both;
        }
    }

    &__inner {
        display: flex;
        align-items: center;

        .form__row {
            flex-grow: 1;
        }
    }

    &__remove-button {
        margin: 9px 0 0 20px;
        align-self: flex-start;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 400;

        @include tablet {
            margin: 22.5px 0 0 20px;
        }

        .icon {
            pointer-events: none;
        }

        &:disabled {
            color: rgba($color-dark-green, 0.5);
        }
    }

    [data-cloned-element]:first-child {
        hr {
            display: none;
        }
    }

    @keyframes fade-container {
        0% {
            opacity: 0;
            max-height: 0;
        }

        100% {
            opacity: 1;
            max-height: 107px;
        }
    }
}
