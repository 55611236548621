@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.section-header {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: #FAFAFA;
  padding: 0.5rem 0 2rem 0; }
  .section-header__title {
    display: inline-flex;
    color: #222222;
    align-items: center; }
  .section-header__btn {
    flex-shrink: 0; }
  .section-header .form-control {
    margin-left: 40px; }
