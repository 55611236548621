.form {
    $this: &;

    position: relative;

    &__row {
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;

        @include tablet {
            margin-bottom: 30px;
        }

        &--inline {
            #{$this}__col {
                width: auto;
            }
        }

        &--space-between {
            @include tablet {
                justify-content: space-between;
                align-items: center;

                #{$this}__col {
                    width: auto;
                }
            }
        }

        .replicator & {
            margin-bottom: 0;
        }
    }

    &__col {
        width: 100%;

        @for $i from 1 through 12 {
            &--#{$i} {
                @include tablet {
                    width: 100% * ($i / 12);
                }
            }
        }

        & + & {
            margin-top: 20px;

            @include tablet {
                margin-top: 0;
                margin-left: 20px;
            }
        }

        &--vertical--center {
            display: flex;
            align-items: center;
        }
    }

    &__submit-btn {
        width: 100%;
        padding: 17px 30px;
        font-size: 20px;

        @include tablet {
            width: auto;
            min-width: 280px;
        }
    }
}
