// https://github.com/necolas/normalize.css/issues/664
body {
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.html {
    position: relative;
    font-size: 100%;

    &.is-blocked {
        overflow: hidden;
    }

    &.is-blocked-touch {
        width: 100%;
        height: auto;
        position: fixed;
        overflow-y: scroll;
    }
}

.body {
    @include ff-specsaversweb-regular;


    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    color: $color-dark-grey;

    @include tablet {
        height: 100vh;
    }
}

.container {
    @include default-side-paddings();

    max-width: $container-max-width;
    margin-right: auto;
    margin-left: auto;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
    outline: none;
}

figure {
    margin: 0;
}
