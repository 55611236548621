.form-select {
    $this: &;
    $max-height: 200px;

    position: relative;

    @include hover {
        #{$this}__icon {
            fill: $color-dark-green;
        }
    }

    &__container {
        position: relative;
    }

    &__control {
        @include form-input;

        display: flex;
        position: relative;
        appearance: none;
        align-items: center;
        font-family: inherit;

        @include tablet {
            padding: 10px 20px;
            font-size: unset;
        }

        &::-ms-expand {
            display: none;
        }
    }

    &__custom-button {
        display: block;
        width: 100%;
        padding: 10px 20px;
        text-align: left;
        font-family: inherit;
        font-size: 14px;
        font-weight: $ff-weight-medium;
        background: none;
        border: none;
        cursor: pointer;
    }

    &__custom-container {
        display: none;

        @include tablet {
            @include form-input;

            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            border-radius: 20px;
            font-size: 14px;
            text-align: left;
            cursor: pointer;
            min-height: 38px;
            max-height: 38px;
            z-index: 1;
            overflow: hidden;
            transition: max-height 0.3s ease, min-height 0.3s ease;
        }


        &.show {
            min-height: $max-height;
            max-height: $max-height;
        }

        &.disabled {
            display: none;
        }
    }

    &__custom-container ~ &__control {
        @include tablet {
            visibility: hidden;
        }
    }

    &__custom-list {
        display: none;

        @include tablet {
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            padding: 0 20px 10px;
            height: calc(100% - 38px);
            max-height: $max-height;
            transform-origin: top;
            transition: max-height 0.3s ease, transform 0.3s ease;
            z-index: 1;
        }
    }

    &__custom-item {
        $point-size: 13px;
        $point-gap: 9px;

        display: block;
        position: relative;
        margin-top: 12px;
        padding: 0 0 0 #{$point-size + $point-gap};
        font-size: 14px;
        font-family: inherit;
        border: none;
        background: none;
        transition: color 0.3s linear;
        cursor: pointer;

        &::before {
            position: absolute;
            content: '';
            display: block;
            left: 0;
            top: 50%;
            width: $point-size;
            height: $point-size;
            border: 1px solid $color-white-five;
            border-radius: 50%;
            box-shadow: inset 0 0 0 2px $color-white;
            background-color: $color-white;
            transition: background-color 0.1s linear;
            transform: translateY(-50%);
        }

        &.active,
        .can-hover &:hover {
            color: $color-medium-green;
        }

        &.active::before {
            background-color: $color-medium-green;
        }
    }

    &__icon {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        margin: 5px auto;
        fill: $color-medium-green;
        pointer-events: none;
        transition: $transition-default, transform 0.3s ease;
        z-index: 1;

        #{$this}.opened & {
            transform: rotate(-180deg);
        }
    }

    &--size-xs {
        #{$this}__control {
            @include form-input-xs;

            padding-right: 40px;
        }

        #{$this}__icon {
            width: 13px;
            height: 12px;
            right: 15px;
            fill: $color-dark-grey;
        }
    }

    &--size-s {
        @include tablet {
            min-width: 186px;
        }

        #{$this}__control {
            @include form-input-xs;

            padding-right: 40px;
        }

        #{$this}__icon {
            width: 13px;
            height: 12px;
            right: 15px;
            fill: $color-dark-grey;
        }
    }

    @keyframes showList {
        0% {
            max-height: $max-height;
        }

        100% {
            max-height: 0;
        }
    }
}
