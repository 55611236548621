.form-switchlist {
    $this: &;

    &__list {
        @include reset-list();

        #{$this}--inline & {
            @include tablet {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &__item {
        & + & {
            margin-top: 10px;

            #{$this}--inline & {
                @include tablet {
                    margin-top: 0;
                    margin-left: 85px;
                }
            }
        }
    }
}
