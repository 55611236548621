@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
@media (--only-mobile) {
  .toggle-area {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 70px;
    left: 0;
    padding: 0; } }

.toggle-area__button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  cursor: default; }
  @media (--only-mobile) {
    .toggle-area__button {
      display: flex;
      width: 100%;
      height: 42px;
      padding: 0 20px;
      background-color: #016C42;
      font-size: 16px;
      color: #FFFFFF;
      justify-content: space-between; } }
  .toggle-area__button.is-open .icon {
    transform: rotate(-180deg); }
  .toggle-area__button .icon {
    transition: transform 0.3s ease-out; }
    @media (--tablet) {
      .toggle-area__button .icon {
        display: none; } }

.toggle-area__area {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0; }
  @media (--only-mobile) {
    .toggle-area__area {
      height: calc(100vh - 190px);
      padding: 20px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      will-change: scroll-position;
      background-color: #FFFFFF; } }
  @media (--tablet) {
    .toggle-area__area {
      position: static; } }
  .toggle-area__area.disabled {
    display: none; }
    @media (--tablet) {
      .toggle-area__area.disabled {
        display: block; } }
  .toggle-area__area.go-show {
    animation: showArea 0.3s ease-out normal both; }
    @media (--tablet) {
      .toggle-area__area.go-show {
        animation: none; } }
  .toggle-area__area.go-hide {
    animation: showArea 0.3s ease-in reverse both; }
    @media (--tablet) {
      .toggle-area__area.go-hide {
        animation: none; } }

@keyframes showArea {
  0% {
    z-index: -1;
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    z-index: -1;
    opacity: 1;
    transform: translateY(0); } }
