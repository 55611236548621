.form-textarea {
    $this: &;

    display: block;
    position: relative;

    &__control {
        width: 100%;
        padding: 13px 15px;
        resize: none;
        min-height: 100px;
        border: 2px solid $color-light-grey;
        border-radius: 2px;
        font-size: 16px;

        .is-invalid & {
            border-color: $color-vermillion;
        }
    }

    &__validity-state-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        pointer-events: none;
    }
}
