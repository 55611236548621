@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.form {
  position: relative; }
  .form__row {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap; }
    @media (--tablet) {
      .form__row {
        margin-bottom: 30px; } }
    .form__row--inline .form__col {
      width: auto; }
    @media (--tablet) {
      .form__row--space-between {
        justify-content: space-between;
        align-items: center; }
        .form__row--space-between .form__col {
          width: auto; } }
    .replicator .form__row {
      margin-bottom: 0; }
  .form__col {
    width: 100%; }
    @media (--tablet) {
      .form__col--1 {
        width: 8.33333%; } }
    @media (--tablet) {
      .form__col--2 {
        width: 16.66667%; } }
    @media (--tablet) {
      .form__col--3 {
        width: 25%; } }
    @media (--tablet) {
      .form__col--4 {
        width: 33.33333%; } }
    @media (--tablet) {
      .form__col--5 {
        width: 41.66667%; } }
    @media (--tablet) {
      .form__col--6 {
        width: 50%; } }
    @media (--tablet) {
      .form__col--7 {
        width: 58.33333%; } }
    @media (--tablet) {
      .form__col--8 {
        width: 66.66667%; } }
    @media (--tablet) {
      .form__col--9 {
        width: 75%; } }
    @media (--tablet) {
      .form__col--10 {
        width: 83.33333%; } }
    @media (--tablet) {
      .form__col--11 {
        width: 91.66667%; } }
    @media (--tablet) {
      .form__col--12 {
        width: 100%; } }
    .form__col + .form__col {
      margin-top: 20px; }
      @media (--tablet) {
        .form__col + .form__col {
          margin-top: 0;
          margin-left: 20px; } }
    .form__col--vertical--center {
      display: flex;
      align-items: center; }
  .form__submit-btn {
    width: 100%;
    padding: 17px 30px;
    font-size: 20px; }
    @media (--tablet) {
      .form__submit-btn {
        width: auto;
        min-width: 280px; } }

.form-control__header {
  margin-bottom: 15px; }
  @media (--tablet) {
    .form-control__header {
      margin-bottom: 20px; } }

.form-control__label {
  display: block;
  font-weight: 500;
  color: #A8A8A7; }
  @media (--tablet) {
    .form-control__label {
      font-size: 18px;
      min-width: 60px; } }
  .form-control__label--size-xs {
    font-size: 12px;
    text-transform: uppercase; }

.form-control__description {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.75; }

.form-control__errors {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  margin-left: 30px; }
  .form-control__errors:not(.filled) {
    display: none; }
  .wizard-buttons .form-control__errors {
    margin: 0; }

.form-control__error-item {
  color: #E60E0E;
  font-size: 14px; }
  .form-control__error-item + .form-control__error-item {
    margin-top: 3px; }

@media (--desktop) {
  .form-control--inline {
    display: flex;
    align-items: center; }
    .form-control--inline .form-control__header {
      min-width: 20%;
      margin-bottom: 0; }
    .form-control--inline .form-control__label {
      margin-right: 25px; }
      .form-control--inline .form-control__label--size-xs {
        margin-right: 15px; }
    .form-control--inline .form-control__content {
      flex-grow: 1; } }

@media (--desktop-wide) {
  .form-control--inline .form-control__header {
    min-width: 15%; } }

.form-control--range {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media (--desktop) {
    .form-control--range {
      flex-wrap: nowrap; } }
  .form-control--range .form-control__label {
    margin-right: 25px;
    flex-shrink: 0; }
    .form-control--range .form-control__label--size-xs {
      margin-right: 15px; }
  .form-control--range .form-control__content {
    flex-grow: 1;
    flex-shrink: 0; }
  .form-control--range .form-control__errors {
    width: 100%; }
    @media (--desktop) {
      .form-control--range .form-control__errors {
        margin-top: 0; } }

#global_errors {
  margin: 0 20px 0 auto; }

.validation-summary-errors {
  font-size: 14px;
  color: #E60E0E; }

.form-textarea {
  display: block;
  position: relative; }
  .form-textarea__control {
    width: 100%;
    padding: 13px 15px;
    resize: none;
    min-height: 100px;
    border: 2px solid #ECECEC;
    border-radius: 2px;
    font-size: 16px; }
    .is-invalid .form-textarea__control {
      border-color: #E60E0E; }
  .form-textarea__validity-state-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    pointer-events: none; }

.form-switch {
  position: relative; }
  .form-switch__label {
    display: flex;
    position: relative;
    align-items: center; }
  .form-switch__tick {
    display: flex;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    cursor: pointer; }
    .form-switch__tick::before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute; }
  .form-switch__text {
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer; }
    .form-switch--compact .form-switch__text {
      margin-left: 10px; }
  .form-switch__control {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    margin: 0;
    opacity: 0;
    cursor: pointer; }
    .form-switch__control:checked ~ .form-switch__label .form-switch__tick::before {
      background-color: #3BAA35; }
    .form-switch__control[type='radio'] ~ .form-switch__label .form-switch__tick {
      width: 30px;
      height: 30px;
      border-radius: 50%; }
      .form-switch__control[type='radio'] ~ .form-switch__label .form-switch__tick::before {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%; }
    @media (--tablet) {
      .form-switch__control[type='radio'] ~ .form-switch__label .form-switch__text {
        color: #222222;
        font-size: 18px; } }
  .form-switch--size-xs .form-switch__control[type='radio'] ~ .form-switch__label .form-switch__tick {
    width: 13px;
    height: 13px; }
    .form-switch--size-xs .form-switch__control[type='radio'] ~ .form-switch__label .form-switch__tick::before {
      content: '';
      width: 7px;
      height: 7px; }
  @media (--tablet) {
    .form-switch--size-xs .form-switch__control[type='radio'] ~ .form-switch__label .form-switch__text {
      color: #50504E;
      font-size: 14px; } }
  .form-switch--size-xs .form-switch__text {
    margin-left: 9px; }

.form-switchlist__list {
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (--tablet) {
    .form-switchlist--inline .form-switchlist__list {
      display: flex;
      flex-wrap: wrap; } }

.form-switchlist__item + .form-switchlist__item {
  margin-top: 10px; }
  @media (--tablet) {
    .form-switchlist--inline .form-switchlist__item + .form-switchlist__item {
      margin-top: 0;
      margin-left: 85px; } }

.form-select {
  position: relative; }
  .can-hover .form-select:hover .form-select__icon {
    fill: #016C42; }
  .form-select__container {
    position: relative; }
  .form-select__control {
    width: 100%;
    padding: 10px 20px;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 999px;
    font-size: 16px;
    color: #222222;
    display: flex;
    position: relative;
    appearance: none;
    align-items: center;
    font-family: inherit; }
    @media (--tablet) {
      .form-select__control {
        padding: 23.5px 30px;
        font-size: 18px; } }
    .is-invalid .form-select__control {
      border-color: #E60E0E; }
    @media (--tablet) {
      .form-select__control {
        padding: 10px 20px;
        font-size: unset; } }
    .form-select__control::-ms-expand {
      display: none; }
  .form-select__custom-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer; }
  .form-select__custom-container {
    display: none; }
    @media (--tablet) {
      .form-select__custom-container {
        width: 100%;
        padding: 10px 20px;
        background-color: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 999px;
        font-size: 16px;
        color: #222222;
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 20px;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
        min-height: 38px;
        max-height: 38px;
        z-index: 1;
        overflow: hidden;
        transition: max-height 0.3s ease, min-height 0.3s ease; } }
  @media (--tablet) and (--tablet) {
    .form-select__custom-container {
      padding: 23.5px 30px;
      font-size: 18px; } }
    @media (--tablet) {
        .is-invalid .form-select__custom-container {
          border-color: #E60E0E; } }
    .form-select__custom-container.show {
      min-height: 200px;
      max-height: 200px; }
    .form-select__custom-container.disabled {
      display: none; }
  @media (--tablet) {
    .form-select__custom-container ~ .form-select__control {
      visibility: hidden; } }
  .form-select__custom-list {
    display: none; }
    @media (--tablet) {
      .form-select__custom-list {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        padding: 0 20px 10px;
        height: calc(100% - 38px);
        max-height: 200px;
        transform-origin: top;
        transition: max-height 0.3s ease, transform 0.3s ease;
        z-index: 1; } }
  .form-select__custom-item {
    display: block;
    position: relative;
    margin-top: 12px;
    padding: 0 0 0 22px;
    font-size: 14px;
    font-family: inherit;
    border: none;
    background: none;
    transition: color 0.3s linear;
    cursor: pointer; }
    .form-select__custom-item::before {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 50%;
      width: 13px;
      height: 13px;
      border: 1px solid #D8D8D8;
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px #FFFFFF;
      background-color: #FFFFFF;
      transition: background-color 0.1s linear;
      transform: translateY(-50%); }
    .form-select__custom-item.active,
    .can-hover .form-select__custom-item:hover {
      color: #3BAA35; }
    .form-select__custom-item.active::before {
      background-color: #3BAA35; }
  .form-select__icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    margin: 5px auto;
    fill: #3BAA35;
    pointer-events: none;
    transition: 0.3s ease-out, transform 0.3s ease;
    z-index: 1; }
    .form-select.opened .form-select__icon {
      transform: rotate(-180deg); }
  .form-select--size-xs .form-select__control {
    width: 100%;
    padding: 10px 20px;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 999px;
    font-size: 14px;
    color: #222222;
    padding-right: 40px; }
    .is-invalid .form-select--size-xs .form-select__control {
      border-color: #E60E0E; }
  .form-select--size-xs .form-select__icon {
    width: 13px;
    height: 12px;
    right: 15px;
    fill: #50504E; }
  @media (--tablet) {
    .form-select--size-s {
      min-width: 186px; } }
  .form-select--size-s .form-select__control {
    width: 100%;
    padding: 10px 20px;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 999px;
    font-size: 14px;
    color: #222222;
    padding-right: 40px; }
    .is-invalid .form-select--size-s .form-select__control {
      border-color: #E60E0E; }
  .form-select--size-s .form-select__icon {
    width: 13px;
    height: 12px;
    right: 15px;
    fill: #50504E; }

@keyframes showList {
  0% {
    max-height: 200px; }
  100% {
    max-height: 0; } }
