.flatpickr-calendar {
    display: none;
    width: 307.875px;
    position: absolute;
    padding: 0;
    background: transparent;
    opacity: 0;
    text-align: center;
    visibility: hidden;
    -webkit-animation: none;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
}

.flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
    display: block;
    z-index: 999;
}

.flatpickr-calendar.multiMonth
.flatpickr-days
.dayContainer:nth-child(n + 1)
.flatpickr-day.inRange:nth-child(7n + 7) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.flatpickr-calendar.multiMonth
.flatpickr-days
.dayContainer:nth-child(n + 2)
.flatpickr-day.inRange:nth-child(7n + 1) {
    -webkit-box-shadow: -2px 0 0 #E6E6E6, 5px 0 0 #E6E6E6;
    box-shadow: -2px 0 0 #E6E6E6, 5px 0 0 #E6E6E6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
    border-bottom: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    border: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
}

.flatpickr-calendar::before,
.flatpickr-calendar::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 22px;
    pointer-events: none;
    border: solid transparent;
}

.flatpickr-calendar.rightMost::before,
.flatpickr-calendar.rightMost::after {
    right: 22px;
    left: auto;
}

.flatpickr-calendar::before {
    margin: 0 -5px;
    border-width: 5px;
}

.flatpickr-calendar::after {
    margin: 0 -4px;
    border-width: 4px;
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
    bottom: 100%;
}

.flatpickr-calendar.arrowTop::before {
    border-bottom-color: rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.arrowTop::after {
    border-bottom-color: #1BBC9B;
}

.flatpickr-calendar.arrowBottom::before,
.flatpickr-calendar.arrowBottom::after {
    top: 100%;
}

.flatpickr-calendar.arrowBottom::before {
    border-top-color: rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.arrowBottom::after {
    border-top-color: #1BBC9B;
}

.flatpickr-calendar:focus {
    outline: 0;
}

.flatpickr-wrapper {
    display: inline-block;
    position: relative;
}

.flatpickr-months {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flatpickr-months .flatpickr-month {
    height: 28px;
    position: relative;
    border-radius: 5px 5px 0 0;
    background: #1BBC9B;
    color: #FFFFFF;
    fill: #FFFFFF;
    line-height: 1;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    height: 28px;
    position: absolute;
    z-index: 3;
    top: 0;
    padding: 10px;
    text-decoration: none;
    cursor: pointer;
    line-height: 16px;
    color: #FFFFFF;
    fill: #FFFFFF;
}

.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
    display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
    position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    /*
      /*rtl:begin:ignore*/
    /*
      */
    left: 0;
    /*
      /*rtl:end:ignore*/
    /*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    /*
      /*rtl:begin:ignore*/
    /*
      */
    right: 0;
    /*
      /*rtl:end:ignore*/
    /*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
    color: #BBBBBB;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: #F64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: inherit;
}

.numInputWrapper {
    height: auto;
    position: relative;
}

.numInputWrapper input,
.numInputWrapper span {
    display: inline-block;
}

.numInputWrapper input {
    width: 100%;
}

.numInputWrapper input::-ms-clear {
    display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

.numInputWrapper span {
    width: 14px;
    height: 50%;
    position: absolute;
    right: 0;
    padding: 0 4px 0 2px;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(72, 72, 72, 0.15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.numInputWrapper span:hover {
    background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
    background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span::after {
    content: '';
    display: block;
    position: absolute;
}

.numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
}

.numInputWrapper span.arrowUp::after {
    top: 26%;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(72, 72, 72, 0.6);
}

.numInputWrapper span.arrowDown {
    top: 50%;
}

.numInputWrapper span.arrowDown::after {
    top: 40%;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(72, 72, 72, 0.6);
}

.numInputWrapper span svg {
    width: inherit;
    height: auto;
}

.numInputWrapper span svg path {
    fill: rgba(255, 255, 255, 0.5);
}

.numInputWrapper:hover {
    background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
    opacity: 1;
}

.flatpickr-current-month {
    display: inline-block;
    width: 75%;
    height: 28px;
    position: absolute;
    left: 12.5%;
    padding: 6.16px 0 0 0;
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    line-height: 1;
    text-align: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
    font-family: inherit;
    font-weight: 700;
    color: inherit;
}

.flatpickr-current-month span.cur-month:hover {
    background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
    display: inline-block;
    width: 6ch;
    width: 7ch\0;
}

.flatpickr-current-month .numInputWrapper span.arrowUp::after {
    border-bottom-color: #FFFFFF;
}

.flatpickr-current-month .numInputWrapper span.arrowDown::after {
    border-top-color: #FFFFFF;
}

.flatpickr-current-month input.cur-year {
    display: inline-block;
    height: auto;
    margin: 0;
    padding: 0 0 0 0.5ch;
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
    outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(255, 255, 255, 0.5);
    background: transparent;
    pointer-events: none;
}

.flatpickr-weekdays {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 28px;
    background: #1BBC9B;
    text-align: center;
    overflow: hidden;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

span.flatpickr-weekday {
    display: block;
    margin: 0;
    cursor: default;
    font-size: 90%;
    background: #1BBC9B;
    color: rgba(0, 0, 0, 0.54);
    line-height: 1;
    text-align: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
    padding: 1px 0 0 0;
}

.flatpickr-days {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 307.875px;
    position: relative;
    overflow: hidden;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-left: 1px solid rgba(72, 72, 72, 0.2);
    border-right: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-days:focus {
    outline: 0;
}

.dayContainer {
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 307.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    padding: 0;
    outline: 0;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.dayContainer + .dayContainer {
    -webkit-box-shadow: -1px 0 0 rgba(72, 72, 72, 0.2);
    box-shadow: -1px 0 0 rgba(72, 72, 72, 0.2);
}

.flatpickr-day {
    display: inline-block;
    width: 14.2857143%;
    max-width: 39px;
    height: 39px;
    position: relative;
    margin: 0;
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #484848;
    cursor: pointer;
    font-weight: 400;
    -webkit-flex-basis: 14.2857143%;
    -ms-flex-preferred-size: 14.2857143%;
    flex-basis: 14.2857143%;
    line-height: 39px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: #E2E2E2;
    border-color: #E2E2E2;
}

.flatpickr-day.today {
    border-color: #BBBBBB;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    border-color: #BBBBBB;
    background: #BBBBBB;
    color: #FFFFFF;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #1BBC9B;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #FFFFFF;
    border-color: #1BBC9B;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 #1BBC9B;
    box-shadow: -10px 0 0 #1BBC9B;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #E2E2E2, 5px 0 0 #E2E2E2;
    box-shadow: -5px 0 0 #E2E2E2, 5px 0 0 #E2E2E2;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
    color: rgba(72, 72, 72, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
    cursor: not-allowed;
    color: rgba(72, 72, 72, 0.1);
}

.flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #1BBC9B, 5px 0 0 #1BBC9B;
    box-shadow: -5px 0 0 #1BBC9B, 5px 0 0 #1BBC9B;
}

.flatpickr-day.hidden {
    visibility: hidden;
}

.rangeMode .flatpickr-day {
    margin-top: 1px;
}

.flatpickr-weekwrapper {
    display: inline-block;
    float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    border-left: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-weekwrapper .flatpickr-weekday {
    width: 100%;
    float: none;
    line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(72, 72, 72, 0.3);
    background: transparent;
    cursor: default;
    border: none;
}

.flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    background: #FFFFFF;
    border-bottom: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.flatpickr-time {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 0;
    text-align: center;
    outline: 0;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 0 0 5px 5px;
}

.flatpickr-time::after {
    content: '';
    display: table;
    clear: both;
}

.flatpickr-time .numInputWrapper {
    width: 40%;
    height: 40px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp::after {
    border-bottom-color: #484848;
}

.flatpickr-time .numInputWrapper span.arrowDown::after {
    border-top-color: #484848;
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
}

.flatpickr-time input {
    height: inherit;
    position: relative;
    margin: 0;
    padding: 0;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    line-height: inherit;
    color: #484848;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
    font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    font-weight: 400;
}

.flatpickr-time input:focus {
    outline: 0;
    border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
    display: inline-block;
    width: 2%;
    height: inherit;
    float: left;
    line-height: inherit;
    color: #484848;
    font-weight: bold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
    width: 18%;
    outline: 0;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
    background: #EAEAEA;
}

.flatpickr-input[readonly] {
    cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fpFadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
