.form-range {
    $this: &;

    display: flex;
    position: relative;
    flex-direction: column;

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        background-color: rgba($color-white-four, 0.5);
        transition: opacity $transition-default;
    }

    &__label {
        position: absolute;
        bottom: 100%;
        margin-bottom: 20px;
        font-size: 12px;
        line-height: normal;
        color: $color-black;

        &--min {
            left: 0;
        }

        &--max {
            right: 0;
        }
    }
}

.noUi-horizontal {
    height: 10px;
    padding: 0;
    border-radius: 999px;
    border: 0;
    background-color: $color-white;
    box-shadow: none;

    .noUi-handle {
        width: 40px;
        height: 40px;
        top: -15px;
        border: 0;
        border-radius: 50%;
        background-color: $color-medium-green;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);

        &::before,
        &::after {
            content: normal;
        }
    }

    .noUi-buffer {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-light-grey;
    }

    html:not([dir='rtl']) & {
        .noUi-handle {
            right: -20px;
        }
    }
}
