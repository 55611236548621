.dropdown {
    $this: &;

    display: inline-flex;

    &__content{
        max-height: 500px;
        overflow-y: auto;

        @include mobile-only {
            max-height: 80vh;
        }
    }

    &__button {
        padding: 10px 20px;
        font-size: 14px;
        color: $color-dark-grey;
        border: 1px solid $color-white-two;
        border-radius: 20px;
        justify-content: space-between;
        transition: border-radius 0.1s linear;

        &:disabled {
            color: $color-greyish;
            cursor: not-allowed;

            @include hover {
                color: $color-greyish;
            }
        }
    }

    &.is-open {
        #{$this}__button {
            border-radius: 20px 20px 0 0;
            border-bottom-color: transparent;
        }

        .icon--arrow {
            transform: rotate(-180deg);
        }
    }

    &--min-on-mobile {
        @include mobile-only {
            #{$this}__button {
                width: 40px;
                height: 40px;
                padding: 0;
                justify-content: center;
                align-items: center;
            }

            #{$this}__current-selection {
                display: none;

                + .icon {
                    margin: 0;
                }
            }
        }
    }

    .icon {
        transition: $transition-default;
    }
}
