/*  reset default styles */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

ul {
    @include reset-list();
}

.title-m {
    font-size: 40px;
    font-weight: $ff-weight-medium;
    line-height: (55/40);
    color: $color-black;

    @include desktop {
        font-size: 50px;
    }
}
.title-s {

    font-size: 30px;
    font-weight: $ff-weight-medium;
    line-height: (42/30);

    @include desktop {
        font-size: 40px;
    }
}

.title-xs {
    font-size: 20px;
    font-weight: $ff-weight-medium;
    line-height: (38/20);
    color: $color-black;

    @include desktop {
        font-size: 24px;
    }
}

.title-xxs {
    font-size: 18px;
    font-weight: $ff-weight-medium;
    line-height: (34/18);
    color: $color-dark-grey;
}

.title-l {
    font-size: 18px;
    line-height: (34/24);
    font-weight: $ff-weight-medium;

    @include tablet {
        font-size: 24px;
    }

    &--regular {
        font-weight: $ff-weight-normal;
    }

    [data-dc-component='tooltip'] {
        margin-left: 8px;
    }
}

.color-dark-green {
    color: $color-dark-green;
}

.color-greyish {
    color: $color-greyish;
}

.faded-out {
    font-size: 12px;
    line-height: (19/14);
    color: $color-greyish;
    @include tablet {
        font-size: 14px;
    }
}

.link {
    @include link;
    @include text-body-copy();
}

.visually-hidden {
    @include visually-hidden;
}

.hr {
    width: 100%;
    height: 2px;
    background-color: $color-light-grey;
    border: 0;
}
