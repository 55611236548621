/*
    put @font-face rules ordered by font-weight within the same font-family
    use woff, woff2
    do not use quotes if you don't have space in font name

    exmaples:

    @font-face {
        font-display: swap;
        font-family: 'Stag Sans';
        src:
            url('../fonts/stag-sans-light.woff2') format('woff2'),
            url('../fonts/stag-sans-light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-display: swap;
        font-family: Stag;
        src:
            url('../fonts/stag-book.woff2') format('woff2'),
            url('../fonts/stag-book.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-display: swap;
        font-family: Stag;
        src:
            url('../fonts/stag-semibold.woff2') format('woff2'),
            url('../fonts/stag-semibold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }
 */

@font-face {
    font-family: $ff-specsaversweb;
    src: url('../fonts/specsaversweb-regular.woff2') format('woff2'),
        url('../fonts/specsaversweb-regular.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: $ff-weight-normal;
}

@font-face {
    font-family: $ff-specsaversweb;
    src: url('../fonts/specsaversweb-medium.woff2') format('woff2'),
        url('../fonts/specsaversweb-medium.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: $ff-weight-medium;
}

@font-face {
    font-family: $ff-specsaversweb;
    src: url('../fonts/specsaversweb-bold.woff2') format('woff2'),
        url('../fonts/specsaversweb-bold.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: $ff-weight-bold;
}
