@mixin reset-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-button() {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

@mixin reset-link() {
    text-decoration: none;
    color: inherit;
}

@mixin object-fill-parent() {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

@mixin object-fit-image-parent($fit: cover) {
    img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: $fit;

        @content;
    }
}

@mixin visually-hidden() {
    width: 1px;
    height: 1px;
    position: absolute;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
}

@mixin default-cta {
    @include reset-button;
    @include ff-specsaversweb-medium;

    font-size: 14px;
    line-height: normal;

    @include desktop {
        font-size: 16px;
    }

    & + &,
    + * {
        margin-left: 20px;

        .form-control & {
            @include tablet-only {
                margin: 20px 0 0;
            }
        }
    }

    span + .icon {
        margin-left: 20px;
    }

    .icon + span {
        margin-left: 20px;
    }

    .icon:only-child {
        margin: 0;
    }

    .is-disabled & {
        pointer-events: none;
    }
}

@mixin default-side-paddings() {
    padding-right: $default-mobile-side-padding;
    padding-left: $default-mobile-side-padding;

    @include tablet {
        padding-right: $default-tablet-side-padding;
        padding-left: $default-tablet-side-padding;
    }

    @include desktop {
        padding-right: $default-desktop-side-padding;
        padding-left: $default-desktop-side-padding;
    }
}

@mixin mobile-expanded() {
    @include mobile-only() {
        margin-right: -$default-mobile-side-padding;
        margin-left: -$default-mobile-side-padding;
    }
}

@mixin tablet-expanded() {
    @include tablet-only() {
        margin-right: -$default-tablet-side-padding;
        margin-left: -$default-tablet-side-padding;
    }
    @include mobile-only() {
        margin-right: -$default-mobile-side-padding;
        margin-left: -$default-mobile-side-padding;
    }
}

@mixin button-small {
    padding: (10/14) + em (20/14) + em;
    font-size: 14px;
}

@mixin button-medium {
    padding: (18/14) + em (30/14) + em;
    font-size: 14px;

    @include desktop-wide {
        padding: (25/16) + em (50/16) + em;
        font-size: 16px;
    }
}

@mixin button-with-grey-border {
    border: solid 1px $color-light-grey;

    &:not([data-dc-component]) {
        .can-hover &:hover {
            border-color: $color-gainsboro;
        }
    }
}

@mixin button-with-green-color {
    color: $color-dark-green;

    &:disabled {
        color: rgba($color-dark-green, 0.5);
    }

    .can-hover &:not(:disabled):hover {
        color: $color-dark-blue-green;
    }
}

@mixin button-with-medium-green-color {
    color: $color-medium-green;

    .can-hover &:hover {
        color: $color-dark-green;
    }
}

@mixin button-with-red-color {
    color: $color-vermillion;

    .can-hover &:hover {
        color: $color-burnt-red;
    }
}

@mixin button-with-green-border {
    border: solid 2px $color-dark-green;

    .can-hover &:hover {
        border-color: $color-dark-blue-green;
    }
}

@mixin button-rounded {
    border-radius: 999px;
}

@mixin button-medium-green() {
    background-color: $color-medium-green;
    color: $color-white;
    border: 0;

    &:not([data-dc-component]) {
        .can-hover &:hover {
            background-color: $color-dark-green;
        }
    }
}

@mixin button-grey() {
    background-color: $color-light-grey;
    border: 0;

    &:not([data-dc-component]) {
        .can-hover &:hover {
            background-color: $color-gainsboro;
        }
    }
}

@mixin link-green() {
    color: $color-dark-green;

    svg {
        fill: currentColor;
    }

    .can-hover &:hover {
        color: $color-dark-green;

        svg {
            fill: currentColor;
        }
    }
}

@mixin link-reset() {
    text-decoration: none;
    color: inherit;
}

@mixin text-link {
    font-size: 14px;
    line-height: (24/14);
    font-weight: $ff-weight-medium;
}

@mixin hover() {
    .can-hover &:hover {
        @content;
    }
}

@mixin link() {
    @include link-reset();
    @include text-link();
    @include link-green();
    @include hover {
        cursor: pointer;
    }
}

@mixin text-body-copy {
    font-size: 14px;
    line-height: (24/14);

    @include desktop {
        font-size: 16px;
    }
}

@mixin form-input {
    width: 100%;
    padding: 10px 20px;
    background-color: $color-white;
    border: 1px solid $color-white-two;
    border-radius: 999px;
    font-size: 16px;
    color: $color-black;

    @include tablet {
        padding: 23.5px 30px;
        font-size: 18px;
    }

    .is-invalid & {
        border-color: $color-vermillion;
    }
}

@mixin form-input-xs {
    width: 100%;
    padding: 10px 20px;
    background-color: $color-white;
    border: 1px solid $color-white-two;
    border-radius: 999px;
    font-size: 14px;
    color: $color-black;

    .is-invalid & {
        border-color: $color-vermillion;
    }
}
