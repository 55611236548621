// spaces values
$spaces: (0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 100, 180);

// spaces CSS properties
$space-prefixes: (
    m: margin,
    mt: 'margin-top',
    mb: 'margin-bottom',
    p: padding,
    pt: 'padding-top',
    pb: 'padding-bottom'
);

@mixin make-spaces($spaces, $prefixes, $breakpoint: '') {
    @each $attr-short, $attr-long in $prefixes {
        @each $value in $spaces {
            // on mobile all spaces are 0 by default
            @if ($breakpoint == 'mobile' and $value == 0) {
                // do nothing
            } @else {
                @if ($attr-short == 'p' or $attr-short == 'm') {
                    .#{$breakpoint}#{if($breakpoint == '','','-')}#{$attr-short}-#{$value} {
                        #{$attr-long}-top: #{$value/16 + rem};
                        #{$attr-long}-bottom: #{$value/16 + rem};
                    }
                } @else {
                    .#{$breakpoint}#{if($breakpoint == '','','-')}#{$attr-short}-#{$value} {
                        #{$attr-long}: #{$value/16 + rem};
                    }
                }
            }
        }
    }
}

@include make-spaces($spaces, $space-prefixes);

@each $breakpoint, $value in $breakpoints {
    @media (--#{$breakpoint}) {
        @include make-spaces($spaces, $space-prefixes, $breakpoint);
    }
}
