$transition-default: 0.3s ease-out;
$container-max-width: $containerMaxWidth + px;

$default-mobile-side-padding: 20px;
$default-tablet-side-padding: 30px;
$default-desktop-side-padding: 30px;

$mobile-header-height: 60px;
$mobile-second-level-height: 42px;
$tablet-header-height: 86px;
$tablet-second-level-height: 67px;

$headerLayer: 100;
$layer-overlay: 300;
$layer-modal: 500;
$layer-element-spinner: 300;
$layer-page-spinner: 600;

$ff-specsaversweb: 'Specsavers Web';
$ff-fallback: Helvetica, Tahoma, sans-serif;

$ff-weight-light: 300;
$ff-weight-normal: 400;
$ff-weight-medium: 500;
$ff-weight-bold: 700;
