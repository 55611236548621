@custom-media --mobile-wide (width >= 580px);
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1200px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1200px);
.show-if-mobile-wide {
  display: none; }
  @media (--mobile-wide) {
    .show-if-mobile-wide {
      display: block; } }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

.show-if-mobile-only {
  display: none; }
  @media (--only-mobile) {
    .show-if-mobile-only {
      display: block; } }

.show-if-tablet-only {
  display: none; }
  @media (--only-tablet) {
    .show-if-tablet-only {
      display: block; } }

.show-if-desktop-only {
  display: none; }
  @media (--only-desktop) {
    .show-if-desktop-only {
      display: block; } }

/*
    create variables for each font-family with fallback
    create mixin for each font-family with default font-weight

    examples:
    $ff-stag-sans: 'Stag Sans', Helvetica, Tahoma, sans-serif;
    $ff-stag: Stag, Trebuchet, serif;

    @mixin f-stag-sans($weight: 400) {
        font-family: $ff-stag-sans;
        font-weight: $weight;
    }

    @mixin f-stag($weight: 400) {
        font-family: $ff-stag;
        font-weight: $weight;
    }

*/
.autocomplete {
  display: inline-block;
  width: 100%; }
  .autocomplete[hidden] {
    display: none; }
  .autocomplete__list-wrap {
    min-width: calc(100% - 60px);
    position: absolute;
    z-index: 40;
    left: 0;
    margin: 20px 30px 0;
    padding: 20px 0;
    border-radius: 9px;
    border: 1px solid #DCDCDC;
    background: #FFFFFF; }
    .autocomplete__list-wrap::after, .autocomplete__list-wrap::before {
      content: ' ';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      left: 50px;
      border: solid transparent;
      pointer-events: none; }
    .autocomplete__list-wrap::after {
      margin-left: -10px;
      border-color: transparent;
      border-bottom-color: #FFFFFF;
      border-width: 10px; }
    .autocomplete__list-wrap::before {
      margin-left: -11px;
      border-color: transparent;
      border-bottom-color: #DCDCDC;
      border-width: 11px; }
  .autocomplete__list {
    margin: 0 5px 0 10px;
    padding: 0;
    max-height: 300px;
    overflow: auto;
    list-style: none;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position; }
    .autocomplete__list > li {
      position: relative;
      padding: 10px 15px;
      font-size: 18px;
      color: #222222;
      cursor: pointer; }
      .autocomplete__list > li:hover, .autocomplete__list > li[aria-selected='true'] {
        background-color: rgba(59, 170, 53, 0.1); }
      .autocomplete__list > li mark {
        font-weight: 500;
        color: inherit;
        background-color: transparent; }
  .autocomplete .spinner::after {
    right: 5px;
    left: auto; }
    @media (--tablet) {
      .autocomplete .spinner::after {
        right: 10px; } }
