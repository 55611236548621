.form-switch {
    $this: &;

    position: relative;

    &__label {
        display: flex;
        position: relative;
        align-items: center;
    }

    &__tick {
        display: flex;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        border: 1px solid $color-white-two;
        cursor: pointer;

        &::before {
            content: '';
            width: 12px;
            height: 12px;
            position: absolute;
        }
    }

    &__text {
        margin-left: 20px;
        font-size: 14px;
        cursor: pointer;

        #{$this}--compact & {
            margin-left: 10px;
        }
    }

    &__control {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        margin: 0;
        opacity: 0;
        cursor: pointer;

        &:checked {
            & ~ #{$this}__label {
                #{$this}__tick {
                    &::before {
                        background-color: $color-medium-green;
                    }
                }
            }
        }

        &[type='radio'] {
            ~ #{$this}__label {
                #{$this}__tick {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;

                    &::before {
                        content: '';
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                    }
                }

                #{$this}__text {
                    @include tablet {
                        color: $color-black;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    &--size-xs {
        #{$this}__control {
            &[type='radio'] {
                ~ #{$this}__label {
                    #{$this}__tick {
                        width: 13px;
                        height: 13px;

                        &::before {
                            content: '';
                            width: 7px;
                            height: 7px;
                        }
                    }

                    #{$this}__text {
                        @include tablet {
                            color: $color-dark-grey;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        #{$this}__text {
            margin-left: 9px;
        }
    }
}
