.modal-button {
    &__content-wrapper {
        position: fixed;
        z-index: 9999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin-left: 0;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
        padding: 5%;

        @include mobile-wide {
            padding: 4% 25%;
        }
    }

    &__close {
        color: #aaa;
        line-height: 50px;
        font-size: 80%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: black;
        }
    }

    &__content {
        z-index: 9998;
        position: relative;
        top: 0;
        left: 0;
        border-radius: 20px;
        padding: 2em;
        background: white;
    }
}