.wizard-buttons {
    @include mobile-expanded;

    display: flex;
    height: $wizard-buttons-height;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;

    @include mobile-only {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
    }

    @include tablet {
        padding: 0 30px 0 0;
        border-right: 2px solid $color-light-grey;
    }

    .btn-secondary:only-child {
        margin-left: auto;
    }
}
