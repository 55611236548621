.get-artwork {
    position: relative;

    &__preview {
        display: flex;
        margin-top: -23px;
        margin-bottom:30px;
        padding: 35px 30px 35px 0;

        @include tablet {
            margin-top: -40px;
        }

        &::before{
            content:'';
            display: block;
            width: 100vw;
            position: absolute;
            z-index: -1;
            top: 0;
            right: -28px;
            bottom: 0;
            background-color: $color-white;
        }
    }

    &__preview-col-1 {
        display: flex;
        width: 100%;
        max-width: 65px;
        flex-shrink: 0;
    }

    &__preview-content-wrapper {
        display: flex;
        flex-direction: column;

        @include mobile-wide {
            flex-direction: row;
        }
    }

    &__preview-content {
        margin: 10px 0 20px 0;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: 0;
        color: $color-greyish;

        @include mobile-wide {
            margin: 10px 0 0 0;
            padding-right: 40px;
        }
    }

    &__preview-label {
        display: inline-flex;
        min-width: 38px;
        padding: 2px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2px;
        color: $color-black;
        background-color: $color-lime-green;
        border-radius: 2px;
    }

    .loader-container {
        .is-loading {
            display: none;
        }

        .is-complete {
            display: block;
        }
    }

    .loader-container.is-loading {
        .is-loading {
            display: block;
        }

        .is-complete {
            display: none;
        }
    }

    .loader-container.error {
        .btn-primary {
            background-color: indianred;
            cursor: not-allowed;
        }
    }

    .error-message {
        margin-top: 20px;
        padding: 20px;
        color: white;
        background-color: indianred;
    }
}

.preview{
    &__content{
        width: 90%;
        height:80vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3D(-50%, -50%, 0);

        @include tablet{
            width: 70%;
            max-width: 800px;
        }
    }

    &__stage{
        background: $color-white;
    }

    &__close{
        display: block;
        position: absolute;
        z-index:99997;
        top: 20px;
        right: 20px;
    }

    &__zoom{
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        z-index:99997;
        right: 20px;
        bottom: 20px;
        padding: 12px;
        border-radius: 50%;
        border: solid 1px $color-light-grey;
        background-color: $color-white;
        cursor: pointer;
        color: $color-dark-green;

        @include tablet{
            color: $color-light-grey;
        }

        .icon-zoomin{
            display: inline;
        }

        .icon-zoomout{
            display: none;
        }

        .fancybox-can-zoomIn &{
            color: $color-dark-green;

            .icon-zoomin{
                display: inline;
            }

            .icon-zoomout{
                display: none;
            }
        }

        .fancybox-can-pan &{
            color: $color-dark-green;

            .icon-zoomin{
                display: none;
            }

            .icon-zoomout{
                display: inline;
            }
        }
    }
}
