.flatpickr-months .flatpickr-month {
    background: $color-dark-green;
}

.flatpickr-weekdays {
    background: $color-dark-green;
}

span.flatpickr-weekday {
    background: $color-dark-green;
    color: $color-white;
    font-weight: $ff-weight-medium;
}

.flatpickr-current-month span.cur-month {
    font-weight: $ff-weight-medium;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $color-dark-green;
    border-color: $color-dark-green;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 $color-dark-green;
    box-shadow: -10px 0 0 $color-dark-green;
}

.flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 $color-dark-green, 5px 0 0 $color-dark-green;
    box-shadow: -5px 0 0 $color-dark-green, 5px 0 0 $color-dark-green;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: $color-lime-green;
}

.flatpickr-calendar {
    &::before,
    &::after {
        right: 0;
        left: 0;
        margin: auto;
        border-width: 12px;
    }

    &.static {
        top: calc(100% + 15px);
    }

    &.arrowTop::after {
        border-bottom-color: $color-dark-green;
    }

    &.arrowBottom::after {
        border-top-color: $color-dark-green;
    }
}
