.tippy-tooltip {
    border-radius: 6px;

    &.light-border-theme {
        .tippy-popper & {
            box-shadow: 0 2px 6px 0 rgba($color-black, 0.06);
            border-color: $color-white-two;
        }
    }

    &[data-size='small'] {
        padding: 0;
    }

    &.dropdown-theme {
        .tippy-popper & {
            padding: 0;
            box-shadow: none;
            border-radius: 0 0 20px 20px;
            border-top-color: transparent;

            .tippy-content {
                padding: 8px 20px 15px;
            }
        }
    }

    .tippy-content {
        padding: 15px 20px;
        text-align: left;
        font-size: 12px;
        line-height: (16/12);
        color: $color-dark-grey;

        strong {
            font-size: 14px;
            font-weight: $ff-weight-medium;
        }
    }
}
